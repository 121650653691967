@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "CrimsonRoman";
  src: url("./fonts/crimson/roman.ttf") format("truetype");
}

body {
  font-family: CrimsonRoman;
  overflow-x: hidden;
}
